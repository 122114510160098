<template>
  <v-content color="primary">
    <v-container fluid id="main-container" grid-list-md>
      <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          top
      >
        {{ t(text) }}
        <v-btn
            flat
            @click="snackbar = false"
        >
          {{t('Close')}}
        </v-btn>
      </v-snackbar>

      <v-layout row wrap justify-center class="d-inline-block w-100">
        <v-card>
          <v-card-title>
            <span class="menu-icon bigger material-symbols-outlined">
              user_attributes
            </span>
            <span class="table-title">{{t('Resellers')}}</span>
            <v-spacer>
            </v-spacer>
            <v-text-field
                v-model="search"
                append-icon="search"
                :label='t("Search")'
                single-line
                hide-details
            >
            </v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="subscribers"
              :total-items="page_size"
              class="elevation-1"
              :loading="loading"
              :search="search"
              :pagination.sync="pagination"
              hide-actions
              :rows-per-page-items="[page_size]"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left no-wrap">{{ props.item.username}}</td>
              <td class="text-xs-left no-wrap">{{ props.item.email }}</td>
              <td class="text-xs-left no-wrap">{{ props.item.role }}</td>
              <td class="text-xs-left no-wrap">{{ props.item.role !== 'Owner' ? parseFloat(props.item.balance).toFixed(2) + $store.getters.currency : ""}}</td>
              <td class="text-xs-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="`/resellers/${props.item.pk}/details/`" color="info"
                         small icon ripple dark>
                      <v-icon small>{{t('info')}}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{t('Detail')}}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="$store.getters.isOwner">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="`/resellers/${props.item.pk}/edit/`" color="success" icon ripple small dark>
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{t('Edit')}}</span>
                </v-tooltip>
              </td>
            </template>
            <template slot="no-data">
              <v-subheader>{{t('No data available')}}</v-subheader>
            </template>
          </v-data-table>
          <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; flex-wrap: wrap;">
            <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                :total-visible="6"
            ></v-pagination>
            <v-select style="margin-left: 10px; max-width: 50px" :items="[10, 25, 50]" v-model="page_size">
            </v-select>
          </div>
        </v-card>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
  import axios from 'axios';
  import {debounce} from 'vue-debounce';

  export default {
    name: "Resellers",
    data() {
      return {
        page_size: 10,
        pagination: {
          current: 1,
          total: 20,
          descending: false,
          sortBy: undefined
        },
        dialog: false,
        next: null,
        previous: null,
        count: 0,
        headers: [
          {
            text: this.$translate.locale['Username'],
            align: 'left',
            //  sortable: false,
            value: 'username',
          },
          {text: this.$translate.locale['Email'], value: 'email'},
          {text: this.$translate.locale['User type'], value: 'role'},
          {text: this.$translate.locale['Balance'], value: 'balance'},
          {text: this.$translate.locale['Action'], value: 'action', sortable: false},
        ],
        subscribers: [],
        loading: true,
        snackbar: false,
        search: '',
        text: 'Oops... Something went wrong',
        timeout: 5000,
        rules: {
          required: value => !!value || this.$translate.locale['Required.'],
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || this.$translate.locale['Invalid e-mail.']
          },
        },
        getDataDebounced: debounce((...args) => this.getData(...args), '300ms'),
        axiosGetDataCancelToken: axios.CancelToken.source(),
      }
    },
    methods: {
      getData(resetPage=false) {
        this.loading = true;
        
        // cancelling previous getData() request
        this.axiosGetDataCancelToken.cancel();
        // creating new token for cancel
        this.axiosGetDataCancelToken = axios.CancelToken.source();

        let url = `/api/resellers/`
        const params = {
          page_size: this.page_size,
          page: this.pagination.current,
          sort_by: this.pagination.descending ? `-${this.pagination.sortBy}` : this.pagination.sortBy,
        };

        if (this.search) {
          params.search = this.search;

          if (this.pagination.current !== 1 && resetPage) {
            this.pagination.current = 1;
            // return, because changing `this.pagination.current`
            // will call getData() one more time
            return;
          }
        }

        axios.get(url, {params, cancelToken: this.axiosGetDataCancelToken.token})
          .then((response) => {
            if (response.status === 200) {
              this.subscribers = response.data.results;
              this.previous = response.data.previous;
              this.next = response.data.next;
              this.count = response.data.count;
              this.pagination.total = response.data.total_pages;
              this.loading = false;
            }
          }).catch((error) => {
            if (axios.isCancel(error)) return;

            this.text = "Connection error";
            this.snackbar = true;
          });
      },
      addressCheck() {
        this.errorMessages = this.address && !this.name
          ? 'Hey! I\'m required'
          : '';

        return true
      },
    },
    beforeCreate() {
      if (!this.$store.getters.loggedIn) {
        this.$router.push('/')
      }
      if(!this.$store.getters.isAdmin && !this.$store.getters.isOwner){
        this.$router.push('/cards')
      }
    },
    mounted() {
      this.getData();
    },
    watch: {
      'pagination.current': function() { this.getDataDebounced(); },
      'pagination.sortBy': function() { this.getDataDebounced(); },
      'pagination.descending': function() { this.getDataDebounced(); },
      'page_size': function(newValue, oldValue) { this.getDataDebounced(newValue !== oldValue); },
      'search': function(newValue, oldValue) { this.getDataDebounced(newValue !== oldValue); },
    }
  }
</script>

<style scoped>

</style>
